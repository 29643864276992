"use client";

import React, { useState } from 'react';
import Link from "next/link";
import { useSearchParams, usePathname } from "next/navigation";
import Image from 'next/image';
import config from '@/config';
import logo from '@/public/favicon-192x192.png';

const MeaningsLabHeader = () => {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const links = [
    // { href: "/popular", label: "Popular Searches" },
    // { href: "/recent", label: "Recent Words" },
    // { href: "/categories", label: "Categories" },
    // { href: "/about", label: "About" },
  ];

  return (
    <header className="bg-white border-b border-gray-200 shadow-sm">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <nav className="flex items-center justify-between h-16" aria-label="Global">
          <div className="flex items-center flex-shrink-0">
            <Link className="flex items-center gap-3" href="/" title={`${config.appName} - Word Meanings and Definitions`}>
              <Image
                src={logo}
                alt={`${config.appName} logo`}
                className="w-8 h-8"
                placeholder="blur"
                priority={true}
                width={32}
                height={32}
              />
              <span className="font-bold text-xl text-gray-900">{config.appName}</span>
            </Link>
          </div>

          {/* <div className="hidden lg:flex lg:items-center lg:justify-center lg:flex-1 space-x-6">
            {links.map((link) => (
              <Link 
                href={link.href} 
                key={link.href} 
                className="px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-blue-600 hover:bg-gray-50 transition duration-300"
              >
                {link.label}
              </Link>
            ))}
          </div> */}
        </nav>
      </div>
    </header>
  );
};

export default MeaningsLabHeader; 