'use client'

import { useState } from 'react'
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { Search } from "lucide-react";
import { useRouter } from 'next/navigation';


export function SearchBox() {
  const [query, setQuery] = useState('')
  const router = useRouter()

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault()
    if (query.trim()) {
      router.push(`/meaning/${query.toLowerCase().replace(/\s+/g, '-')}`)
    }
  }

  return (
    <div className="w-full max-w-2xl mx-auto">
      <h1 className="text-4xl font-bold text-center mb-8">Search Meanings</h1>
      <form onSubmit={handleSearch} className="flex gap-2">
        <Input
          type="text"
          placeholder="Enter a word or phrase to search..."
          className="flex-1 p-2 border rounded-lg"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <Button 
          type="submit"
          variant="ghost" 
          className="px-4 py-2 bg-blue-500 text-white rounded-lg"
        >
          <Search className="w-5 h-5" />
        </Button>
      </form>
    </div>
  )
} 